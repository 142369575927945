import React, { Component } from "react"

import { Icon } from "@components/icon"

import * as styles from "./contact-us-box.module.css"

class ContactUsBox extends Component {
  state = {}
  render() {
    if (this.props.show) {
      return (
        <div className={styles.container}>
          <div className={styles.header}>Get in Touch!</div>
          <div className={styles.buttonsWrapper}>
            <a href="tel:01283512111" className={`${styles.button} btn`}>
              <Icon id="phone" width="16" height="16" />
              <span className={styles.phoneNumber}>01283 512 111</span>
            </a>
            <a
              href="mailto: sales@firstfence.co.uk"
              className={`${styles.button} btn`}
            >
              <Icon id="envelope" width="16" height="16" />
              sales@firstfence.co.uk
            </a>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ContactUsBox
