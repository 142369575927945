import React from "react"

import { Icon } from "@components/icon"

import * as styles from "./contact-us.module.css"

const ContactUs = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Contact Us</div>
      <div className={styles.text}>First Fence Ltd - Head Office</div>
      <div className={styles.text}>Kiln Way</div>
      <div className={styles.text}>Swadlincote</div>
      <div className={styles.text}>Derbyshire</div>
      <div className={styles.text}>DE11 8EA</div>
      <div className={styles.links}>
        <div className={styles.link}>
          <Icon id="envelope" width="20" height="20" />
          <a href="mailto:sales@firstfence.co.uk">sales@firstfence.co.uk</a>
        </div>
        <div className={styles.link}>
          <Icon id="phone" width="20" height="20" />
          <a href="tel:01283512111">01283 512 111</a>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
